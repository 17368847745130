$color-white: #fff;
$color-black: #000;
$color-primary: #2196F3;
$color-secondary: #88898b;
$color-info: #00BCD4;
$color-warning: #F57C00;
$color-danger: #D32F2F;
$color-success: #4CAF50;
$color-text: #4b4b4b;

$modal-backdrop-bg: $color-black;
$modal-backdrop-opacity: .8;
$modal-title-line-height: 1em;
$modal-content-box-shadow-xs: 0 .5rem 1rem rgba(0,0,0,.25);
$modal-content-box-shadow-sm-up: 0 .5rem 3rem rgba(0,0,0,.25);
$border-radius:     3px;
$border-radius-lg:  3px;
$border-radius-sm:  3px;

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons";

main {
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .btn {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}

.list-group {
  .list-group-item {
    background: transparent;
    text-decoration: none;
    &:hover {
      background: rgba(255,255,255,0.5);
    }
  }
}

.videos {
  .card {
    transition: all 0.5s;
    &:hover {
      @extend .shadow;
      border-color: $light;
    }
  }
}
.form-floating > label {
  left: auto;
}
.alert-notice {
  @extend .alert-info;
}
.alert-alert {
  @extend .alert-danger;
}

.checkout {
  .payment_methods {
    .payment_method {
      border: 2px solid #ccc;
      border-radius: 5px;
      &:hover {
        border: 2px solid $primary;
      }
      a {
        text-decoration: none;
        font-weight: bolder;
        font-size: 1.4em;
      }
    }
  }
}

.page-header {
  background-color: lighten(#0090cd, 45);
}